import React, { useState, useEffect } from 'react'
import { Card } from 'antd'
import { PrimaryButton } from '../../../ui'
import style from './cookie-style.m.less'

const CookieModal: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false)

    useEffect(() => {
        // Проверяем наличие флага в localStorage
        const cookieAccepted = localStorage.getItem('CookieAccepted')
        if (!cookieAccepted) {
            setIsVisible(true)
        }
    }, [])

    const handleAccept = () => {
        localStorage.setItem('CookieAccepted', 'true') // Сохраняем флаг
        setIsVisible(false) // Закрываем модальное окно
    }

    return (
        <>
            {isVisible && (
                <div className={style.cookieCard}>
                    <div className={style.cookieBody}>
                        <p>Продолжая использование данного сайта, вы соглашаетесь на обработку файлов cookie, которые могут включать в себя: сведения о местоположении; тип, язык и версию операционной системы и браузера; сведения об используемом устройстве. Данные обрабатываются для предоставления услуг и улучшения качестве работы веб-сайта и сервисов.</p>
                        <PrimaryButton key="accept" value={'Принять'} onClick={handleAccept} />
                    </div>
                </div>
            )}
        </>
    )
}

export default CookieModal
