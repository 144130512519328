import { FC, useState } from 'react'
import moment from 'moment'
import useRequest from '../hooks/useRequest'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../ui/BackTitle/BackTitle'
import { useNavigate, useLocation } from 'react-router-dom'
import Card from '../ui/Card/Card'
import { Divider, Alert, Pagination } from 'antd'
import { Box } from '../ui/Box'
import { CenteredSpin, ImportButton, PrimaryButton } from '../ui'
import { currencyFormat } from '../helpers'
import { ApproveModal, CloseEditModal } from './components'
import style from './style.m.less'
import { getList, infoConfig } from './config'
import useMount from '../hooks/useMount'
import {useCurrentUser} from "../hooks/useCurrentUser";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    filters?: any
}

const ThirdPartyToolsApproval: FC = () => {
    const { fetch, result, isLoading } = useRequest(getList)
    const { fetch: fetchTotal, result: resultTotal, isLoading: isLoadingTotal } = useRequest(getList)
    const [approveModalVisible, isApproveModalVisible] = useState(false)
    const [refuseModalVisible, isRefuseModalVisible] = useState(false)
    const navigate = useNavigate()
    const { state } = useLocation()
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const {data} = useCurrentUser()

    const getDataList = ({ page = 1, pageSize = 10 }: PaginationType) => {
        fetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    ts_created: state?.record?.ts_created,
                },
            },
            errorCodeMessage: true,
        })
    }

    const getDataListTotal = ({ page = 1, pageSize = 100 }: PaginationType) => {
        fetchTotal({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    ts_created: state?.record?.ts_created,
                },
            },
            errorCodeMessage: true,
        })
    }

    useMount(() => {
        scrollToTop()
        getDataList({ page: 1, pageSize: pagination.pageSize })
        getDataListTotal({ page: 1, pageSize: 10000000 })
    })

    const [actionConfirmed, setActionConfirmed] = useState(false)

    if (!result || isLoading || !resultTotal || isLoadingTotal || actionConfirmed) {
        return <CenteredSpin />
    }

    function roundDecimal(value, decimals) {
        return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
    }

    /*let total_cost_with_tax: number = 0;
    resultTotal?.ext_instruments?.forEach( instr => {
        let cost: number = Math.max( 0.05, Number( ( instr.volume * 0.005 ).toFixed( 2 ) ) );
        let tax: number = Number( ( cost * 0.2 ).toFixed( 2 ) );
        let cost_with_tax: number = Number( ( cost + tax ).toFixed( 2 ) );
        total_cost_with_tax = Number( ( total_cost_with_tax + cost_with_tax ).toFixed( 2 ) );
    } )
    let total_cost_with_tax_: string = "" + total_cost_with_tax.toFixed( 2 ) + " ₽";*/

    let total_cost_with_tax: number = 0;
    resultTotal?.ext_instruments?.forEach( instr => {
        let cost: number = Math.max( 0.05, roundDecimal((instr.volume * 0.005 ), 2));
        let tax: number = roundDecimal(( cost * 0.2 ), 2);
        let cost_with_tax: number = roundDecimal(( cost + tax ), 2);
        total_cost_with_tax = roundDecimal(( total_cost_with_tax + cost_with_tax ), 2);
    } )
    let total_cost_with_tax_: string = "" + roundDecimal(total_cost_with_tax, 2) + " ₽";

    return (
        <PageContainer>
            <>
                <BackLink title={`Согласование данных сторонних инструментов`} />
                <Card className={style.card}>
                    <div className={style.infoWrapper}>
                        {infoConfig(state?.record)?.map((item: any) => (
                            <Box direction="row">
                                <div className={style.greyBlock}>{item.name}</div>
                                <div>{item.value}</div>
                            </Box>
                        ))}
                    </div>
                    <div className={style.energyBlock}>
                        <div className={style.energyWrapper}>
                            <div className={style.energyTopLeft}>Номер Стороннего инструмента</div>
                            <div className={style.energyTop}>Объем, кВт*ч</div>
                        </div>
                        {result?.ext_instruments?.map((item: any, index: number) => {
                            return (
                                <div
                                    className={
                                        index === result?.ext_instruments?.length - 1
                                            ? style.energyItemWrapperBottom
                                            : style.energyItemWrapper
                                    }
                                >
                                    <div className={style.energyItemLeft}>{item.number}</div>
                                    <div className={style.energyItem}>
                                        {currencyFormat(item.volume)}
                                    </div>
                                </div>
                            )
                        })}
                        {result?.ext_instruments?.length && (
                            <Pagination
                                className={style.pagination}
                                pageSize={pagination.pageSize}
                                current={pagination.page}
                                total={result?.total}
                                onChange={(page, pageSize) => {
                                    getDataList({ page, pageSize })
                                    setPagination({ page, pageSize })
                                }}
                                showSizeChanger
                            />
                        )}
                    </div>
                    <Divider />
                    <Box direction="row" justify="space-between">
                        <Box direction="row" gap="16px">
                            <PrimaryButton
                                onClick={() => navigate(-1)}
                                value="Отмена"
                                isCancel
                                customStyle={{ padding: '8px 16px' }}
                            />
                            <PrimaryButton
                                onClick={() => isApproveModalVisible(true)}
                                value="Подписать и оплатить"
                                customStyle={{ padding: '8px 16px' }}
                            />
                        </Box>
                        <PrimaryButton
                            onClick={() => isRefuseModalVisible(true)}
                            value="Отказаться"
                            isDelete
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </Box>
                </Card>
            </>
            <ApproveModal
                data={state?.record}
                total_cost_with_tax={total_cost_with_tax_}
                isModalVisible={approveModalVisible}
                setIsModalVisible={isApproveModalVisible}
                setActionConfirmed={setActionConfirmed}
                userData={data}
            />
            <CloseEditModal
                data={state?.record}
                isModalVisible={refuseModalVisible}
                setIsModalVisible={isRefuseModalVisible}
                userData={data}
            />
        </PageContainer>
    )
}

export default ThirdPartyToolsApproval
