import moment from 'moment'
import {monthPicker} from "../components/helpers";
moment.locale('ru')

export const renderDate = (date: Date) => (date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : '-')

export const renderDateMonth = (date: Date) => (date ? moment(date).format('DD MMMM') : '-')

export const renderTime = (date: Date) => (date ? moment(date).format('HH:mm') : '-')

export const renderDayMonthYear = (date: Date) => (date ? moment(date).format('DD.MM.YYYY') : '-')

export const renderMonthAsTextYear = (date: Date) => (
    date
        ? monthPicker(Number(moment(date).format('MM'))) + ', ' + moment(date).format('YYYY')
        : '-'
)

export const getUserName = (user: any, isShort: boolean = false) => {
    if (!user) return ''

    const { first_name, last_name, middle_name } = user

    return `${last_name} ${isShort ? first_name.slice(0, 1) : first_name} ${
        isShort ? middle_name.slice(0, 1) : middle_name
    }`
}
