import { FC, useEffect, useState } from 'react'
import { currencyFormat } from '../../../helpers'
import { Button, Pagination, Select } from 'antd'
import { useSelector } from 'react-redux'
import style from './style.m.less'
import request from '../../../../services/request'
import IAppState from '../../../../store/states'
import { EUserRole } from '../../../../constants/approval'

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
}

interface IRepaymentsBlock {
    eventDetails: any
    getCustomData: (data: PaginationType) => void
}

const RepaymentsBlock: FC<IRepaymentsBlock> = ({ eventDetails, getCustomData }) => {
    const userInfo = useSelector((store: IAppState) => store.user)
    const isAdminOrOperator =
        userInfo.data.user_role === EUserRole.SuperAdmin ||
        userInfo.data.user_role === EUserRole.Administrator ||
        userInfo.data.user_role === EUserRole.Operator
    const showAllRepayments = isAdminOrOperator || userInfo.data.uid === eventDetails.uid_creator
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [selectValue, setSelectValue] = useState<string>('---')
    const [disabledButton, setDisabledButton] = useState<boolean>(true)

    const getList = () => {
        if (showAllRepayments) {
            return eventDetails.payload.repayments
        } else {
            const data = []
            for (const repayment of eventDetails.payload.repayments) {
                if (repayment.user_id === userInfo.data.uid) {
                    data.push(repayment)
                }
            }
            return data
        }
    }
    const getTotal = () => {
        if (showAllRepayments) {
            return eventDetails.payload.total
        } else {
            let data = 0
            for (const repayment of eventDetails.payload.repayments) {
                if (repayment.user_id === userInfo.data.uid) {
                    data++
                }
            }
            return data
        }
    }
    const list = getList()
    const total = getTotal()

    const getData = () => {
        const data = []
        if (showAllRepayments) {
            for (const repayment of eventDetails.payload.repayments) {
                data.push({ value: repayment.user_id, label: repayment.user_name })
            }
        } else {
            for (const repayment of eventDetails.payload.repayments) {
                if (repayment.user_id === userInfo.data.uid) {
                    data.push({ value: repayment.user_id, label: repayment.user_name })
                }
            }
        }
        return data
    }
    const selectChange = (value: string) => {
        setSelectValue(value)
    }
    useEffect(() => {
        if (selectValue !== '---') {
            setDisabledButton(false)
        }
    }, [selectValue])

    async function downloadPdf() {
        const repayment = eventDetails.payload.repayments.find(
            (repayment: any) => repayment.user_id === selectValue,
        )
        /* const { data: result } = await request.get(
            '/api/extension/v1/green_tools/all_green_repayments/',
        )
        const value = result.detail.find((v: any) => v.user_id === selectValue)*/
        const { certificate_id, generator_id, owner_generator_id, certificate_number } =
            eventDetails.payload
        const { data: greenToolData } = await request.get(
            '/api/extension/v1/green_tools/user_audit_repayments',
            {
                params: {
                    user_id: selectValue,
                    certificate_id: certificate_id,
                    certificate_number: certificate_number,
                },
            },
        )
        const { user_id, user_name, volume, account_number } = repayment
        const { data: pdfFile, headers: pdfHeaders } = await request.get(
            '/api/extension/v1/files/pdf_file_new',
            {
                params: {
                    user_id: user_id,
                    template_type: 'new',
                    account_number: account_number,
                    volume: volume,
                    generator_id: generator_id,
                    owner_generator_id: owner_generator_id,
                    certificate_id: certificate_id,
                    client_name: user_name,
                    idx: greenToolData.detail.idx,
                    ts_event: eventDetails.ts_event,
                },
                responseType: 'blob',
            },
        )
        const file_ref = document.createElement('a')
        file_ref.href = URL.createObjectURL(new Blob([pdfFile], { type: 'application/pdf' }))
        file_ref.download = pdfHeaders['filename']
        file_ref.target = '_blank'
        file_ref.click()
        URL.revokeObjectURL(file_ref.href)
        file_ref.remove()
    }

    async function downloadArchive() {
        const file_ref = document.createElement('a')
        const { certificate_id, generator_id, owner_generator_id, certificate_number } =
            eventDetails.payload
        for (const repayment of eventDetails.payload.repayments) {
            const { data: greenToolData } = await request.get(
                '/api/extension/v1/green_tools/user_audit_repayments',
                {
                    params: {
                        user_id: repayment.user_id,
                        certificate_id: certificate_id,
                        certificate_number: certificate_number,
                    },
                },
            )
            //const value = result.detail.find((v: any) => v.user_id === repayment.user_id)
            const { user_id, user_name, volume, account_number } = repayment
            const { data: pdfFile, headers: pdfHeaders } = await request.get(
                '/api/extension/v1/files/pdf_file_new',
                {
                    params: {
                        user_id: user_id,
                        template_type: 'new',
                        account_number: account_number,
                        volume: volume,
                        generator_id: generator_id,
                        owner_generator_id: owner_generator_id,
                        certificate_id: certificate_id,
                        client_name: user_name,
                        idx: greenToolData.detail.idx,
                        ts_event: eventDetails.ts_event,
                    },
                    responseType: 'blob',
                },
            )
            file_ref.href = URL.createObjectURL(new Blob([pdfFile], { type: 'application/pdf' }))
            file_ref.download = pdfHeaders['filename']
            file_ref.target = '_blank'
            file_ref.click()
            URL.revokeObjectURL(file_ref.href)
        }
        file_ref.remove()
    }

    return (
        <>
            <div className={style.energyTableWrap}>
                <div className={style.energyBlock}>
                    <div className={style.energyHeader}>{`Потребители СП (${total})`}</div>
                    <div className={style.energyWrapper}>
                        <div className={style.energyTop}>Потребитель</div>
                        <div className={style.energyTop}>Объем, кВт*ч</div>
                    </div>
                    {list?.map((item: any, index: number) => {
                        return (
                            <div
                                className={
                                    index === list.length - 1
                                        ? style.energyItemWrapperBottom
                                        : style.energyItemWrapper
                                }
                            >
                                <div className={style.energyItem}>{item.user_name}</div>
                                <div className={style.energyItem}>
                                    {currencyFormat(item.volume)}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={style.dropdownWrap}>
                    <div>
                        <div className={style.dropdownLabel}>
                            Потребитель СП для свидетельства о погашении СП
                        </div>
                        <Select
                            showSearch
                            placeholder="Выберите потребителя"
                            className={style.dropdownMenu}
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={selectChange}
                            options={getData()}
                        />
                    </div>
                    <div>
                        <Button disabled={disabledButton} onClick={downloadPdf}>
                            Выгрузить свидетельство о погашении СП
                        </Button>
                    </div>
                    <div>
                        <Button onClick={downloadArchive}>Выгрузить все</Button>
                    </div>
                </div>
            </div>
            {total > 10 && (
                <Pagination
                    className={style.pagination}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    total={total || 0}
                    onChange={(page, pageSize) => {
                        getCustomData({ page, pageSize })
                        setPagination({ page, pageSize })
                    }}
                    showSizeChanger
                />
            )}
        </>
    )
}

export default RepaymentsBlock
