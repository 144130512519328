import BackLink from '../ui/BackTitle/BackTitle'
import { FC, useState } from 'react'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import { useNavigate, useLocation } from 'react-router-dom'
import Card from '../ui/Card/Card'
import { Divider, Pagination } from 'antd'
import { Box } from '../ui/Box'
import { CenteredSpin, PrimaryButton } from '../ui'
import { currencyFormat } from '../helpers'
import style from './style.m.less'
import { infoConfig } from './config'
import useMount from '../hooks/useMount'
import { ApproveModal } from './components'
import useRequest from "../hooks/useRequest";
import {getAccounts} from "../ui/GreenToolsModal/config";
import {getContracts} from "../UserGreenTools/config";
import {SubModal} from "../UserContract/components";
import {FolderCross} from "../../assets/icons";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    filters?: any
}
interface IContractsGroupPayment {
    isAuth: boolean
    isUserType?: boolean
}
const ContractsGroupPayment: FC<IContractsGroupPayment> = ({ isAuth, isUserType }) => {
    const {fetch: fetchContracts, result: resultContracts, isLoading: isLoadingContracts} = useRequest(getContracts)
    const [approveModalVisible, isApproveModalVisible] = useState(false)
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const navigate = useNavigate()
    const { state } = useLocation()
    const [accountId, setAccountID] = useState<string>(state?.record?.accountId)
    const [actionConfirmed, setActionConfirmed] = useState(false)
    const [isSubModalVisible, setIsSubModalVisible] = useState(false)

    const {
        fetch: fetchAccounts,
        result: accounts,
        isLoading: isLoadingAccounts,
    } = useRequest(getAccounts)

    const getDataList = ({ page = 1, pageSize = 10 }: PaginationType) => {
        fetchContracts({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    status_id: 7,
                    account_id: accountId
                },
            },
            errorCodeMessage: true,
        })
    }

    useMount(() => {
        scrollToTop()
        getDataList({ page: 1, pageSize: pagination.pageSize })
    })

    const handleSubModal = () => {
        setIsSubModalVisible(!isSubModalVisible)
    }

    if (!resultContracts || isLoadingContracts || actionConfirmed) {
        return <CenteredSpin />
    }

    return (
        <PageContainer>
            <>
                <BackLink title={`Оплата всех Зеленых договоров`} />
                {resultContracts?.total > 0 && (
                    <Card className={style.card}>
                        <div className={style.infoWrapper}>
                            {infoConfig({
                                period: state?.record?.period,
                                count: resultContracts?.total,
                                volume: resultContracts?.totalVolumes
                            })?.map((item: any) => (
                                <Box direction="row">
                                    <div className={style.greyBlock}>{item.name}</div>
                                    <div>{item.value}</div>
                                </Box>
                            ))}
                        </div>
                        <div className={style.energyBlock}>
                            <div className={style.energyWrapper}>
                                <div className={style.energyTopLeft}>Номер Зеленого договора</div>
                                <div className={style.energyTop}>Объем, кВт*ч</div>
                                <div className={style.energyTop}>
                                    Комиссия за присвоение
                                    <br/> АГ признака ЗД
                                </div>
                            </div>
                            {resultContracts?.contracts?.map((item: any, index: number) => {
                                return (
                                    <div
                                        className={
                                            index === resultContracts?.contracts?.length - 1
                                                ? style.energyItemWrapperBottom
                                                : style.energyItemWrapper
                                        }
                                    >
                                        <div className={style.energyItemLeft}>{item.contract_number}</div>
                                        <div className={style.energyItem}>
                                            {currencyFormat(item.volume_new)}
                                        </div>
                                        <div className={style.energyItem}>
                                            {currencyFormat(item.seller_fee) + ' ₽'}
                                        </div>
                                    </div>
                                )
                            })}
                            {resultContracts?.contracts?.length && (
                                <Pagination
                                    className={style.pagination}
                                    pageSize={pagination.pageSize}
                                    current={pagination.page}
                                    total={resultContracts?.total}
                                    onChange={(page, pageSize) => {
                                        getDataList({page, pageSize})
                                        setPagination({page, pageSize})
                                    }}
                                    showSizeChanger
                                />
                            )}
                        </div>
                        <Divider/>
                        <Box direction="row" justify="start">
                            <Box direction="row" gap="16px">
                                <PrimaryButton
                                    onClick={() => navigate(-1)}
                                    value="Отмена"
                                    isCancel
                                    customStyle={{padding: '8px 16px'}}
                                />
                                <PrimaryButton
                                    onClick={() => isApproveModalVisible(true)}
                                    value="Подписать и оплатить"
                                    customStyle={{padding: '8px 16px'}}
                                />
                            </Box>
                        </Box>
                    </Card>
                )}
                {resultContracts?.total <= 0 && (
                    <Card className={style.card}>
                        <Box>
                            <FolderCross/>
                            <div className={style.textAlert}>
                                У вас нет неоплаченных
                                <br/> Зеленых договоров
                            </div>
                        </Box>
                    </Card>
                )}
            </>
            <ApproveModal
                data={{
                    period: state?.record?.period,
                    count: resultContracts?.total,
                    volume: resultContracts?.totalVolumes,
                    totalSellerFee: resultContracts?.totalSellerFee
                }}
                // total_cost_with_tax={total_cost_with_tax_}
                isModalVisible={approveModalVisible}
                setIsModalVisible={isApproveModalVisible}
                setActionConfirmed={setActionConfirmed}
                openSubModal={handleSubModal}
                accountId={accountId}
            />
            <SubModal isVisible={isSubModalVisible} onClose={handleSubModal}/>
        </PageContainer>
    )
}

export default ContractsGroupPayment
