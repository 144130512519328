// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cookieCard_JGiCy {\n  width: 800px;\n  z-index: 1000;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.2);\n  border-radius: 4px;\n  overflow: hidden;\n  position: fixed;\n  background: #fff;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: 8px;\n  font-size: 18px;\n  padding: 16px 24px;\n}\n.cookieCard_JGiCy p {\n  margin: 0;\n}\n.cookieBody_Hbyck {\n  display: flex;\n  flex-direction: row;\n  justify-items: center;\n  align-content: center;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/components/CookieModal/cookie-style.m.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,6CAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;AACF;AAEA;EACE,SAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;EACA,QAAA;AADF","sourcesContent":[".cookieCard {\n  width: 800px;\n  z-index: 1000;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.2);\n  border-radius: 4px;\n  overflow: hidden;\n  position: fixed;\n  background: #fff;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: 8px;\n  font-size: 18px;\n  padding: 16px 24px;\n}\n\n.cookieCard p {\n  margin: 0;\n}\n\n.cookieBody {\n  display: flex;\n  flex-direction: row;\n  justify-items: center;\n  align-content: center;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookieCard": "cookieCard_JGiCy",
	"cookieBody": "cookieBody_Hbyck"
};
export default ___CSS_LOADER_EXPORT___;
