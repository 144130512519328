import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Box } from '../../../ui'
import { Checkbox } from 'antd'
import { ButtonsBlock } from '../../../Edit/components'
import { useGetTariffRate } from '../../../hooks'
import {currencyFormat, signBody} from '../../../helpers'
import {groupPaymentConfig} from '../../config'
import useRequest from '../../../hooks/useRequest'

import style from './style.m.less'
import IAppState from "../../../../store/states";

interface IApproveModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    setActionConfirmed: (value: boolean) => void   
    data: any
    // total_cost_with_tax: string
    openSubModal?: () => void
    accountId: string
}

const ApproveModal: FC<IApproveModal> = ({ isModalVisible, setIsModalVisible, setActionConfirmed, data/*, total_cost_with_tax*/, openSubModal, accountId }) => {
    const navigate = useNavigate()
    const [checked, setIsChecked] = useState(false)
    const {fetch: fetchGroupPayment, isLoading: isLoadingGroupPayment} = useRequest(groupPaymentConfig)
    const {count, volume, period, totalSellerFee} = data
    const userInfo = useSelector((store: IAppState) => store.user)
    const userData = userInfo.data
    const {balance, rate, rateNumber, balanceNumber} = useGetTariffRate({
        dependency: isModalVisible,
        id: 18,
        volume,
    })

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleConfirm = async () => {
        /* Если не достаточно баланса */
        if (totalSellerFee && balanceNumber < totalSellerFee) {
            handleCancel()
            setActionConfirmed(false)
            openSubModal?.()
            return
        }
        const body = {
            account_id: accountId,
            fee: totalSellerFee,
            // period_date: period,
            volume
        }
        const { request_id, signature } = await signBody(body, userData)
        setIsModalVisible(false)
        setActionConfirmed(true)
        navigate('/user-my-green-tools')
        fetchGroupPayment({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            successMessage: {
                title: 'Успешно',
                description: `Оплата успешно прошла`,
            },
            errorCodeMessage: true,
            onSuccess: () => navigate('/user-my-green-tools'),
            showError: true,
        })
    }

    const onChange = (e: any) => {
        setIsChecked(e.target.checked)
    }

    return (
        <Modal isModalVisible={isModalVisible} bodyStyle={{ width: '600px' }}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>Оплата всех ЗД</div>
                </div>
            </div>
            <div className={style.infoWrapper}>
                <Box direction="row">
                    <div className={style.greyBlock}>Количество ЗД</div>
                    <div>{count}</div>
                </Box>
                <Box direction="row">
                    <div className={style.greyBlock}>Общий объем</div>
                    <div>{`${currencyFormat(volume)} кВт*ч`}</div>
                </Box>
            </div>
            <div className={style.payAlert}>
                <div className={style.payHeader}>
                    <span className={style.alertMessage}>Сумма комиссии составила </span>
                    <span className={style.alertPrice}>{`${currencyFormat(totalSellerFee)} ₽`}</span>
                </div>
                <div className={style.payText}>
                    <span className={style.alertDetail}>Баланс платежного счета: </span>
                    <span className={style.alertDetail}>{balance}</span>
                </div>
            </div>
            <Checkbox checked={checked} className={style.checkbox} onClick={onChange}>
                {`Подтверждаю оплату ЗД`}
            </Checkbox>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleConfirm}
                    cancelAction={handleCancel}
                    disableCondition={!checked}
                    confirmTitle="Подписать и оплатить"
                />
            </div>
        </Modal>
    )
}

export default ApproveModal
