import { FC, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '../../../helpers'
import { Link } from 'react-router-dom'
import { Alert, Divider, Spin, Tooltip } from 'antd'
import FileSaver from 'file-saver'
import { Card, IconButton, LoadingContainer, OperationHistoryMapper } from '../../../ui'
import { ArrowRight } from '../../../../assets/icons'
import { EHistoryType } from '../../../ui/OperationHistoryMapper/types'
import useRequest from '../../../hooks/useRequest'
import useMount from '../../../hooks/useMount'
import { moneyAmountFormatter } from '../../../helpers/moneyAmountFormatter'
import {
    getBalances,
    getCredentials,
    getPaymentHistoryLimited,
    getPdfFile,
    getPackages,
    getLimitVolume,
} from './configurations'
import { getOperations } from './getOperations'
import { BuyPackageModal } from '../'
import moment from 'moment'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import style from './style.m.less'
import request from '../../../../services/request'

const requisitesArray = () => [
    { label: 'Получатель', value: 'recipient' },
    { label: 'ИНН получателя', value: 'inn' },
    { label: 'Банк получателя', value: 'bank_name' },
    { label: 'БИК банка получателя', value: 'bic' },
    { label: 'КПП получателя', value: 'kpp' },
    { label: 'К/С банка получателя', value: 'corr_acc' },
    { label: 'Счет получателя в банке получателя', value: 'beneficiary_acc' },
    {
        label: 'Назначение платежа',
        value: 'reason',
    },
]

interface IPaymentAccount {
    userId?: string
    userBalances: {
        payment_acc_number: string
    }
}

const PaymentAccount: FC<IPaymentAccount> = ({ userId, userBalances }) => {
    const { t } = useTranslation()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { isUserAdmin, userInfo } = useCurrentUser()

    const {
        fetch: fetchCreds,
        result: credentials,
        isLoading: isLoadingCreds,
    } = useRequest(getCredentials)

    const {
        fetch: fetchBalances,
        result: balances,
        isLoading: isLoadingBalances,
    } = useRequest(getBalances)

    const {
        fetch: fetchPaymentHistory,
        result: paymentHistory,
        isLoading: isLoadingPaymentHistory,
    } = useRequest(getPaymentHistoryLimited)

    const {
        fetch: fetchPackages,
        result: packagesResult,
        isLoading: isLoadingPackages,
    } = useRequest(getPackages)

    const [limitState, setLimitState] = useState('-')
    const { fetch: fetchFile, isLoading: isLoadingFile } = useRequest(getPdfFile)

    useMount(() => {
        fetchCreds({})
        fetchPaymentHistory({
            body: { filters: { paid_only: true } },
            getParams: userId && { id: userBalances.payment_acc_number },
        })
        if (!userId) {
            fetchBalances({})
        }
    })

    async function getVolume() {
        const { data: res } = await request.get(getLimitVolume.link, {
            params: {
                user_id: isUserAdmin ? userId : userInfo.uid,
            },
        })
        setLimitState(res.detail)
    }
    useEffect(() => {
        if (packagesResult && packagesResult.active[0]) {
            getVolume()
        }
    }, [packagesResult])

    useEffect(() => {
        if (!isModalVisible) {
            fetchPackages(
                userId
                    ? {
                          getParams: {
                              uid: userId,
                          },
                      }
                    : {},
            )
        }
    }, [isModalVisible])
    const balancesData = userId ? userBalances : balances

    const isLoading =
        isLoadingCreds || isLoadingBalances || isLoadingPaymentHistory || isLoadingPackages

    const billingArray = requisitesArray()

    const paymentHistoryParsed = useMemo(() => {
        if (!paymentHistory?.total) {
            return []
        }
        return getOperations(paymentHistory.events)
    }, [paymentHistory])

    const handlePdfDownload = () => {
        fetchFile({
            getParams: { sum: 0 },
            onSuccess: (data) => {
                const linkSource = `data:application/pdf;base64,${data}`
                const fileName = `Реквизиты для пополнения платежного счета ${balancesData.payment_acc_number}.pdf`
                FileSaver.saveAs(linkSource, fileName)
            },
        })
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <div className={style.paymentAccount}>
                <div className={style.commonInfoBlock}>
                    <div className={style.header}>
                        <span className={style.billInfo}>Счет № </span>
                        {balancesData?.payment_acc_number}
                    </div>
                    <div className={style.commonInfoRow}>
                        <div className={style.billBlock}>
                            <Tooltip
                                placement="topLeft"
                                color="#434343"
                                overlayInnerStyle={{ color: 'white', width: '330px' }}
                                title="Общая сумма денежных средств на платежном счете (доступно + зарезервировано + заблокировано)"
                            >
                                <div className={style.commonLabel}>Баланс счета</div>
                                <div className={style.commonInfo}>
                                    {moneyAmountFormatter.format(balancesData?.total_balance)}
                                </div>
                            </Tooltip>
                        </div>
                        <div className={style.infoBillBlock}>
                            <div>
                                <Tooltip
                                    placement="topLeft"
                                    color="#434343"
                                    overlayInnerStyle={{ color: 'white', width: '320px' }}
                                    title="Свободные денежные средства, которыми можно оплачивать операции"
                                >
                                    <div className={style.commonLabel}>Доступно</div>
                                    <div className={style.commonInfo}>
                                        {moneyAmountFormatter.format(
                                            balancesData?.available_balance,
                                        )}
                                    </div>
                                </Tooltip>
                            </div>
                            <Divider type="vertical" className={style.verticalDivider} />
                            <div>
                                <div className={style.reservedWrapper}>
                                    <Tooltip
                                        placement="topLeft"
                                        color="#434343"
                                        overlayInnerStyle={{ color: 'white', width: '320px' }}
                                        title={`Денежные средства, зарезервированные под операции, которые еще не исполнены. Деньги спишутся окончательно, если операция будет исполнена, или вернутся в "Доступно", если операция не будет исполнена`}
                                    >
                                        <div>
                                            <div className={style.commonLabel}>Зарезервировано</div>
                                            <div className={style.commonInfo}>
                                                {moneyAmountFormatter.format(
                                                    balancesData?.reserved_balance,
                                                )}
                                            </div>
                                        </div>
                                    </Tooltip>
                                    <div className={style.reservedButton}>
                                        <Link to="/all-operations">
                                            <ArrowRight />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {packagesResult && (
                    <Card className={style.card}>
                        <div className={style.header}>Пакет услуг по внесению записей</div>
                        {!isUserAdmin && (
                            <IconButton
                                customStyle={{ marginTop: '32px' }}
                                onClick={() => setIsModalVisible(true)}
                                disabled={!packagesResult.purchasable?.length}
                            >
                                Купить пакет услуг
                            </IconButton>
                        )}
                        <Alert
                            message={
                                <>
                                    <div>
                                        Тариф начинает действовать со следующего месяца после
                                        покупки.
                                    </div>
                                    <div>Срок действия пакета услуг 3 месяца.</div>
                                    <div>
                                        Подключение следующего пакета услуг будет доступно за 14
                                        дней до окончания текущего пакета услуг.
                                    </div>
                                </>
                            }
                            className={style.infoAlert}
                            type="info"
                            showIcon
                        />
                        <div className={style.requisiteRowFirst}>
                            <div className={style.requisiteLabel}>Стоимость</div>
                            <div className={style.requisiteValue}>
                                {packagesResult.price
                                    ? `${currencyFormat(packagesResult.price)} р.`
                                    : '-'}
                            </div>
                        </div>
                        <div className={style.requisiteRow}>
                            <div className={style.requisiteLabel}>
                                Период действия текущего
                                <div>пакета услуг</div>
                            </div>
                            <div className={style.requisiteValue}>
                                {packagesResult.active?.[0]
                                    ? `с ${moment
                                          .utc(packagesResult.active?.[0]?.effective_from)
                                          .format('DD.MM.YYYY')} по ${moment
                                          .utc(packagesResult.active?.[0]?.effective_to)
                                          .format('DD.MM.YYYY')}`
                                    : '-'}
                            </div>
                        </div>
                        <div className={style.requisiteRow}>
                            <div className={style.requisiteLabel}>
                                Период действия следующего
                                <div>пакета услуг</div>
                            </div>
                            <div className={style.requisiteValue}>
                                {packagesResult.active?.[1]
                                    ? `с ${moment
                                          .utc(packagesResult.active?.[1]?.effective_from)
                                          .format('DD.MM.YYYY')} по ${moment
                                          .utc(packagesResult.active?.[1]?.effective_to)
                                          .format('DD.MM.YYYY')}`
                                    : '-'}
                            </div>
                        </div>
                        <div className={style.requisiteRow}>
                            <div className={style.requisiteLabel}>
                                Доступный лимит бесплатного
                                <div>погашения СП</div>
                            </div>
                            <div className={style.requisiteValue}>{limitState}</div>
                        </div>
                    </Card>
                )}
                <div className={style.requisitesAndHistoryRow}>
                    {!userId && (
                        <div className={style.requisitesBlock}>
                            <div className={style.header}>Реквизиты</div>
                            <Alert
                                className={style.historyAlert}
                                message={
                                    'Для пополнения платежного счета необходимо перечислить денежные средства по указанным ниже банковским реквизитам.\n' +
                                    'Зачисление осуществляется в течение 1-3 рабочих дней'
                                }
                                type="info"
                                showIcon
                            />
                            {billingArray?.map((item, index) => {
                                const { label, value } = item
                                return (
                                    <div
                                        key={index}
                                        className={
                                            index === 0
                                                ? style.requisiteRowFirst
                                                : style.requisiteRow
                                        }
                                    >
                                        <div className={style.requisiteLabel}>{label}</div>
                                        <div className={style.requisiteValue}>
                                            {credentials?.[value]}
                                        </div>
                                    </div>
                                )
                            })}
                            <Spin spinning={isLoadingFile}>
                                <IconButton
                                    onClick={handlePdfDownload}
                                    customStyle={{ marginTop: '32px' }}
                                >
                                    Выгрузить реквизиты
                                </IconButton>
                            </Spin>
                        </div>
                    )}
                    <div className={`${style.historyBlock} ${userId ? style.adminView : ''}`}>
                        <div className={style.header}>История операций</div>
                        {paymentHistoryParsed.length > 0 && (
                            <>
                                <OperationHistoryMapper
                                    config={paymentHistoryParsed}
                                    settings={{ historyType: EHistoryType.Payment }}
                                    isShort
                                />
                                <Link
                                    to={
                                        userId
                                            ? `/user/operations-history/${userBalances.payment_acc_number}`
                                            : '/all-operations'
                                    }
                                >
                                    <IconButton customStyle={{ marginTop: '40px' }}>
                                        Все операции
                                    </IconButton>
                                </Link>
                            </>
                        )}
                        {(!paymentHistoryParsed || paymentHistoryParsed.length === 0) && (
                            <Card className={style.card} style={{ boxShadow: 'none' }}>
                                <Alert
                                    className={style.historyAlert}
                                    message={t('myProfile.historyEmptyPayments')}
                                    type="info"
                                    showIcon
                                />
                            </Card>
                        )}
                    </div>
                </div>
            </div>
            <BuyPackageModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                fee={packagesResult?.price}
                userBalance={balancesData?.available_balance}
            />
        </LoadingContainer>
    )
}

export default PaymentAccount
