import {FC, useEffect, useState} from 'react'
import { Tabs } from 'antd'
import style from './style.m.less'
import {Link, useLocation} from 'react-router-dom'
import { Search, Check } from './components'

const { TabPane } = Tabs

const tabsList = [
    { label: 'Поиск ЗИ', key: 0, content: <Search /> },
    { label: 'Проверка потребления по ЗИ', key: 1, content: <Check /> },
]

const UnregisteredLayout: FC = () => {
    const [currentOption, setCurrentOption] = useState('0')
    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const certificate = params.get('certificate_number')
        const document = params.get('document_number')

        if (certificate && document) {
            setCurrentOption('1')
        }
    }, [location.search])

    return (
        <div className={style.mainWrapper}>
            <div className={style.registryInfoBlock}>
                Укажите данные о зеленом инструменте для ознакомления с доступной информацией.
                <br />
                <Link to="/register">Регистрация</Link> в СКИЗИ позволяет получить полный доступ к
                информации о зеленых инструментах.
            </div>
            <Tabs
                defaultActiveKey="0"
                onChange={(activeKey) => {
                    setCurrentOption(activeKey)
                }}
                activeKey={currentOption}
                className={style.tabs}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={label} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default UnregisteredLayout
