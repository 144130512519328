import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { Tabs } from 'antd'
import {
    Pagination,
    CertificateCard,
    Card,
    ContractCard,
    LoadingContainer,
    ThirdPartyToolsCard,
} from '../../../ui'
import { Box } from '../../../ui/Box/Box'
import { ControlsBlock, Default } from './components'
import {
    getCertificates,
    getContracts,
    getOther,
    getNewOther,
    getRedeemedToMe,
    getAccounts,
} from '../../config'
import { usePrevious } from '../../../hooks'
import useRequest from '../../../hooks/useRequest'
import { monthPicker, statusIdPicker } from '../../../helpers'
import { useAppSelector } from '../../../../store/hooks'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { FolderCross } from '../../../../assets/icons'
import { IContract, IOther } from './types'

import style from './style.m.less'

const { TabPane } = Tabs

interface IRegisteredLayout {
    isUserType?: boolean
    isGOOwner?: boolean
    isClientUser?: boolean
    isTSOGPUser?: boolean
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    filters?: any
}

const RegisteredLayout: FC<IRegisteredLayout> = ({isUserType, isGOOwner, isClientUser, isTSOGPUser }) => {
    const tabsList = isGOOwner ? [
        { label: 'Сертификаты происхождения', key: 0, content: <></> },
        { label: 'Зеленые договоры', key: 1, content: <></> },
        { label: 'Сторонние инструменты', key: 2, content: <></> },
    ] : [
        { label: 'Сертификаты происхождения', key: 0, content: <></> },
        { label: 'Зеленые договоры', key: 1, content: <></> },
    ]

    const certificatesList = [
        { label: 'Активные', id: '5d0bbe80-2e0e-4698-8daf-7df24ce6ba83' },
        { label: 'Погашенные', id: 'fe794a9b-3213-40bc-82b4-aac2919f177e' },
        { label: 'Погашенные в меня', id: '4e794a9b-3213-40bc-82b4-aac2919f177e11' },
        { label: 'Заблокированные', id: 'gq894a9b-3213-40bc-82b4-aac2919f177e' },
        { label: 'Истек срок действия', id: '7q1bbe80-2e0e-4698-8daf-7df24ce6ba83' },
        { label: 'Удаленные', id: 'bd3bbe80-2e0e-4698-8daf-7df24ce6ba83' },
        { label: 'На ЭТП', id: '5g194a9b-3213-40bc-82b4-aac2919f177e' },
    ]
    
    const contractsList =  isGOOwner ? [
        { label: 'Активные', id: '5b7f77bf-a3ec-4441-9b13-e46eabc3c027' },
        { label: 'Заблокированные', id: '87f3386e-0358-4f90-bc8b-b655ce181bb8' },
        { label: 'Неоплаченные', id: '4e794a9b-3213-40bc-82b4-aac2919f177e22' },
    ] : (
        isClientUser || isTSOGPUser ? [
            { label: 'Активные', id: '5b7f77bf-a3ec-4441-9b13-e46eabc3c027' },
            { label: 'Заблокированные', id: '87f3386e-0358-4f90-bc8b-b655ce181bb8' },
            { label: 'Потребители не указаны', id: '5de09b87-5cd8-4c01-b4d8-3e866ee5b067' },
        ] : [
            { label: 'Активные', id: '5b7f77bf-a3ec-4441-9b13-e46eabc3c027' },
            { label: 'Заблокированные', id: '87f3386e-0358-4f90-bc8b-b655ce181bb8' },
        ]
    )
    
    const otherList = [
        { label: 'Активные', id: '5d0bbe80-2e0e-4698-8daf-7df24ce6ba83' },
        { label: 'Погашенные', id: 'fe794a9b-3213-40bc-82b4-aac2919f177e' },
        { label: 'Новые', id: 'gq894a9b-3213-40bc-82b4-aac2919f177e' },
    ]
    
    const [currentTool, setCurrentTool] = useState(tabsList[0].label)
    const optionsList =
        currentTool === 'Сертификаты происхождения'
            ? certificatesList
            : currentTool === 'Зеленые договоры'
            ? contractsList
            : otherList
    const [option, setOption] = useState(optionsList[0].label)

    const { renewableEnergyTypesMap } = useAppSelector(selectDictionariesMaps) ?? {}

    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    const {
        fetch: fetchAccounts,
        result: accountsResult,
        isLoading: isLoadingAccounts,
    } = useRequest(getAccounts)
    const {
        fetch,
        result: greenTools,
        isLoading: isLoadingGreenTools,
    } = useRequest(getCertificates)
    const {
        fetch: fetchContracts,
        result: contracts,
        isLoading: isLoadingContracts,
    } = useRequest(getContracts)
    /*
    const {
        fetch: fetchRedeemedToMe,
        result: redeemedToMe,
        isLoading: isLoadingRedeemedToMe,
    } = useRequest(getRedeemedToMe)
    */ 
    const { fetch: fetchOther, result: others, isLoading: isLoadingOther } = useRequest(getOther)
    const {
        fetch: fetchNewOther,
        result: newOthers,
        isLoading: isLoadingNewOther,
    } = useRequest(getNewOther)

    const isGreenContracts = currentTool === 'Зеленые договоры'
    const isCertificate = currentTool === 'Сертификаты происхождения'
    const isThirdPart = currentTool === 'Сторонние инструменты'
    const isNewData = option === 'Новые'
    const thirdPartyToolsData = isNewData ? newOthers : others

/*    
    const result =
        currentTool === 'Зеленые договоры'
            ? contracts
            : currentTool === 'Сертификаты происхождения'
            ? option === 'Погашенные в меня'
                ? redeemedToMe
                : greenTools
            : thirdPartyToolsData
*/            
    const result =
        currentTool === 'Сертификаты происхождения'
            ? greenTools
            : currentTool === 'Зеленые договоры'
            ? contracts
            : thirdPartyToolsData
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>({})

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        if (accountsResult?.accounts?.length) {
            ;(isGreenContracts ? fetchContracts : isCertificate ? fetch : fetchOther)({
                body: {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters,
                },
                errorCodeMessage: true,
            })
        }
    }

    const prevTool = usePrevious(currentTool)
    useEffect(() => {
        const newFilters = { ...filters, status_id: 1 }
        setOption(optionsList[0].label)
        setFilters(newFilters)

        if (Object.keys(newFilters).length) {
            if (prevTool !== currentTool) {
                setFilters({
                    status_id: 1,
                    account_id: filters.account_id || '',
                })
                getData({
                    filters: {
                        status_id: 1,
                        account_id: filters.account_id || '',
                    },
                })
            } else {
                getData({ filters: newFilters })
            }
        }
    }, [currentTool])

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        getData({ page: 1, pageSize: pagination.pageSize, filters: updatedFilter })
    }

    const onChange = (value: string) => {
        setOption(value)
/*        
        if (accountsResult?.accounts?.length) {
            if (value === 'Новые') {
                fetchNewOther({ errorCodeMessage: true })
            } else if (value === 'Погашенные в меня') {
                fetchRedeemedToMe({})
            } else {
                updateFilters(statusIdPicker(value), 'status_id')
            }
        }
*/   
        if (accountsResult?.accounts?.length) {
            if (value === 'Новые') {
                fetchNewOther({ errorCodeMessage: true })
            } else {
                updateFilters(statusIdPicker(value), 'status_id')
            }
        }
    }
/*
    const isLoading =
        isLoadingContracts ||
        isLoadingGreenTools ||
        isLoadingOther ||
        isLoadingNewOther ||
        isLoadingRedeemedToMe
*/
    const isLoading =
        isLoadingContracts ||
        isLoadingGreenTools ||
        isLoadingOther ||
        isLoadingNewOther

    const totalCount = accountsResult?.accounts?.length ? result?.total : 0

    return (
        <div className={style.mainWrapper}>
            <Tabs
                defaultActiveKey="0"
                onChange={(activeKey) => {
                    const activeNumber = Number(activeKey)
                    setCurrentTool(tabsList[activeNumber].label)
                }}
                className={style.tabs}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={label} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
            <ControlsBlock
                fetchAccounts={fetchAccounts}
                accountsResult={accountsResult}
                isLoadingAccounts={isLoadingAccounts}
                options={optionsList}
                currentOption={option}
                toolType={currentTool}
                isUserType={isUserType}
                isThirdPart={isThirdPart}
                onChange={onChange}
                total={totalCount}
                isDisabled={!result?.certificates?.length}
                onChangeFilters={updateFilters}
                filters={filters}
                isGOOwner={isGOOwner}
            />
            <LoadingContainer isLoading={isLoading}>
                <div className={!isNewData ? style.cardsWrapper : undefined}>
                    {result?.certificates?.map((item, index) => {
                        const {
                            certificate_id,
                            certificate_number,
                            renewable_energy_type_id,
                            status_id,
                            volume,
                            generator_name,
                            generator_id,
                            ts_created,
                            consumption_volume,
                            ts_expired,
                            month,
                            year,
                            is_deposit,
                            ts_deleted,
                            is_etp,
                        } = item
                        const productionPeriod = `${monthPicker(month)} ${year}`

                        return (
                            <div
                                key={certificate_number}
                                className={index % 2 ? style.cardBlockMarginLeft : style.cardBlock}
                            >
                                <CertificateCard
                                    isRegistered
                                    isUserType={isUserType}
                                    toolType={currentTool}
                                    certificateNumber={certificate_number}
                                    option={status_id}
                                    consumptionVolume={consumption_volume}
                                    energyType={getEnergyType(renewable_energy_type_id)}
                                    capacity={volume}
                                    generator_id={generator_id}
                                    productionPeriod={productionPeriod}
                                    generatorName={generator_name}
                                    certificateId={certificate_id}
                                    createdTs={moment(ts_created).format('DD.MM.YYYY')}
                                    expiredTs={moment(ts_expired).format('DD.MM.YYYY')}
                                    deletedTs={
                                        ts_deleted ? moment(ts_deleted).format('DD.MM.YYYY') : '-'
                                    }
                                    isDeposit={is_deposit}
                                    isEtp={is_etp}
                                    isOwner={true}
                                />
                            </div>
                        )
                    })}
                    {result?.contracts?.map((contract: IContract) => (
                        <ContractCard
                            isRegistered
                            isOwner
                            contract={contract}
                            key={contract.contract_id}
                        />
                    ))}
                    {isNewData ? (
                        <Default data={result} />
                    ) : (
                        result?.ext_instruments?.map((tool: IOther) => (
                            <ThirdPartyToolsCard isRegistered isOwner tool={tool} key={tool.id} />
                        ))
                    )}
                </div>
                {!result?.total && !isNewData && (
                    <Card className={style.card}>
                        <Box>
                            <FolderCross />
                            <div className={style.textAlert}>
                                У вас пока нет зеленых
                                <br /> инструментов
                            </div>
                        </Box>
                    </Card>
                )}
            </LoadingContainer>

            {result?.total > 10 && (
                <Pagination
                    paginationChange={(page, pageSize) => {
                        getData({ page, pageSize, filters })
                        setPagination({ page, pageSize })
                    }}
                    total={result?.total}
                    current={pagination.page}
                    pageSize={pagination.pageSize}
                    showSizeChanger={true}
                    customStyle={{ width: '100%' }}
                />
            )}
        </div>
    )
}

export default RegisteredLayout
