import { FC, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownSelector2, TimeRangePicker } from '../../../ui'
import moment, { Moment } from 'moment'
import useRequest from '../../../hooks/useRequest'
import { selectDictionariesOptions } from '../../../../store/dictionaries'
import { postGraphData } from './config'
import { useAppSelector } from '../../../../store/hooks'
import Graph from '../../../ui/Graph/Graph'
import style from './style.m.less'

const GraphFrame: FC = () => {
    const { t } = useTranslation()
    const { fetch, result } = useRequest(postGraphData)
    const [type, setType] = useState(0)
    const [range, setRange] = useState<[Moment, Moment] | null>()
    const { renewableEnergyTypesOptions } = useAppSelector(selectDictionariesOptions) ?? {}

    if (!range) {
        setRange([
            moment("январь 24", "MMMM YY").startOf('month'), 
            moment("декабрь 24", "MMMM YY").startOf('month')
        ])
    }    

    const rangeHandler = (r: [Moment, Moment]) => {
        if (!r) {
            setRange(null)
            return
        }
        setRange([moment(r[0]).startOf('month'), moment(r[1]).startOf('month')])
    }

    const realRenewableEnergyTypesOptions = useMemo(
        () =>
            renewableEnergyTypesOptions
                ? [
                      { id: 0, value: 0, text: 'Все ВИЭ', label: 'Все ВИЭ' },
                      ...renewableEnergyTypesOptions,
                  ]
                : [{ id: 0, value: 0, text: 'Все ВИЭ', label: 'Все ВИЭ' }],
        [renewableEnergyTypesOptions],
    )

    const fetchData = () => {
        const types = {
            renewable_energy_type_id: type,
        }
        const body = range
            ? {
                  ...{
                      event_date_from: moment(range[0]).startOf('day'),
                      event_date_to: moment(range[1]).endOf('day'),
                  },
                  ...types,
              }
            : { ...types }
        fetch({
            body,
        })
    }

    useEffect(() => {
        fetchData()
    }, [type, range])

    return (
        <div className={style.graphFrameMain}>
            <div className={style.headerRow}>
                <div>
                    <span className={style.headerPrimary}>{t('main.graphVolume')}</span>
                    <span className={style.headerSecondary}>({t('main.scale')})</span>
                </div>
                <div className={style.filters}>
                    <DropdownSelector2
                        customStyle={{ width: '223px' }}
                        options={realRenewableEnergyTypesOptions}
                        onChange={(value) => setType(value)}
                    />
                    <TimeRangePicker
                        style={{ width: '223px' }}
                        onlyBeforeDateAndToday={moment()}
                        picker="month"
                        value={range}
                        format={'MMMM YY'}
                        onChange={(value) => rangeHandler(value)}
                    />
                </div>
            </div>
            <div className={style.graph}>
                <Graph stats={result?.stats} period={range} />
            </div>
        </div>
    )
}

export default GraphFrame
