import {FC, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import { Input, message } from 'antd'
import { Link } from 'react-router-dom'
import { IconButton, Alert, Card, Box } from '../../../../../ui'
import useRequest from '../../../../../hooks/useRequest'
import { getConsuming } from '../../../../config'
import { monthPicker, currencyFormat } from '../../../../../helpers'
import moment from 'moment'
import style from './style.m.less'
import {Flash} from "../../../../../../assets/icons";

export const payTypePicker = (type: number): string => {
    switch (type) {
        case 1:
            return 'Сертификат происхождения'
        case 2:
            return 'Зеленый договор'
        case 3:
            return 'Сторонние инструменты'
        default:
            return ''
    }
}

const Check: FC = () => {
    const [certificateNumber, setCertificateNumber] = useState('')
    const [documentNumber, setDocumentNumber] = useState('')
    const [isInitializedFromQuery, setIsInitializedFromQuery] = useState(false)
    const { fetch, result, dropState } = useRequest(getConsuming)
    const location = useLocation()

    const onChangeCertificateNumber = (e: any) => {
        setCertificateNumber(e.target.value)
    }

    const onChangeDocumentNumber = (e: any) => {
        setDocumentNumber(e.target.value)
    }

    const onFinish = () => {
        if (!certificateNumber) {
            message.error('Номер ЗИ не должен быть пустым')
        } else if (!documentNumber) {
            message.error('ИНН, СНИЛС или ОГРН не заполнен')
        } else {
            fetch({
                body: {
                    limit: 10,
                    offset: 0,
                    filters: {
                        green_tool_number: certificateNumber,
                        inn_snils_ogrn_number: documentNumber,
                    },
                },
                onError: dropState(),
                errorCodeMessage: true,
                onSuccess: (data) => {
                    if (!data.consumers.length) {
                        message.error('ЗИ с заданными параметрами не найдены')
                    }
                },
            })
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const certificate = params.get('certificate_number')
        const document = params.get('document_number')

        if (certificate && document) {
            setCertificateNumber(certificate)
            setDocumentNumber(document)
            setIsInitializedFromQuery(true)
        }
    }, [location.search])

    useEffect(() => {
        if (isInitializedFromQuery && certificateNumber && documentNumber) {
            onFinish()
            setIsInitializedFromQuery(false)
        }
    }, [isInitializedFromQuery, certificateNumber, documentNumber])

    const createCard = () => {
        if (result?.consumers?.length) {
            const cards = result.consumers.map((item) => {
                const {
                    type,
                    number,
                    total_volume,
                    consumer_volume,
                    ts_repayment,
                    ts_delivery,
                    consumer_name,
                    generators_data
                } = item
                const productionPeriod = `${monthPicker(moment(ts_delivery).month() + 1)}, ${moment(
                    ts_delivery,
                ).year()}`
                const isContract = type === 2

                return (
                    <Card className={style.card}>
                        <div className={style.header}>Информация о потреблении</div>
                        <div className={style.wrapper}>
                            <Box direction="row">
                                <div className={style.leftPart}>Тип ЗИ</div>
                                <div>{payTypePicker(type)}</div>
                            </Box>
                            <Box direction="row">
                                <div className={style.leftPart}>Номер ЗИ</div>
                                <div>{number}</div>
                            </Box>
                            <Box direction="row">
                                <div className={style.leftPart}>
                                    {isContract ? 'Дата операции' : 'Дата погашения'}
                                </div>
                                <div>{moment(ts_repayment).format('DD.MM.YYYY')}</div>
                            </Box>
                            <Box direction="row">
                                <div className={style.leftPart}>
                                    {isContract ? 'Период поставки' : 'Период производства'}
                                </div>
                                <div>{productionPeriod}</div>
                            </Box>
                            <Box direction="row">
                                <div className={style.leftPart}>
                                    {isContract ? 'Общий объем по периоду' : 'Общий объем СП'}
                                </div>
                                <div>{`${currencyFormat(total_volume)} кВт*ч`}</div>
                            </Box>
                            <Box direction="row">
                                <div className={style.leftPart}>Потребитель</div>
                                <div>{consumer_name}</div>
                            </Box>
                            <Box direction="row">
                                <div className={style.leftPart}>Потребленный объем</div>
                                <div>{`${currencyFormat(consumer_volume)} кВт*ч`}</div>
                            </Box>
                        </div>
                        {generators_data ?
                            generators_data.map(generator_data => (
                                <Box margin="10px 0px" display="flex">
                                    <Link to={`/generator-card/${generator_data.generator_id}`} className={style.link}>
                                        <Box direction="row" align="center">
                                            <Flash />
                                            <Box margin="0px 5px">{generator_data.generator_name}</Box>
                                        </Box>
                                    </Link>
                                </Box>
                            ))
                            : ''
                        }
                    </Card>
                )
            })
            return <Box gap={'16px'}>{cards}</Box>
        }
    }

    return (
        <>
            <div className={style.selectorWrapper}>
                <Alert
                    message={
                        <div>
                            <div>
                                Для проверки потребления атрибутов генерации по зеленому
                                инструменту,
                            </div>
                            <div>
                                в котором вы указаны как потребитель, необходимо заполнить все поля
                                поиска.
                            </div>
                        </div>
                    }
                    type="info"
                    className={style.alert}
                />
                <div className={style.numberInputWrapper}>
                    <div>
                        <div>Номер ЗИ</div>
                        <div className={style.inputBlock}>
                            <Input
                                value={certificateNumber}
                                onChange={onChangeCertificateNumber}
                                className={style.certificateNumberInput}
                                placeholder={`Введите номер ЗИ`}
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>ИНН, СНИЛС или ОГРН</div>
                        <div className={style.inputBlock}>
                            <Input
                                value={documentNumber}
                                onChange={onChangeDocumentNumber}
                                className={style.documentNumberInput}
                                placeholder="Введите номер"
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <IconButton onClick={onFinish} customStyle={{ height: '32px' }}>
                            Найти
                        </IconButton>
                    </div>
                </div>
            </div>
            {createCard()}
        </>
    )
}

export default Check
