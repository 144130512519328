import useRequest, { RequestTypes } from './useRequest'
import { currencyFormat } from '../helpers'
import { useEffect } from 'react'

interface IGetTariff {
    id: number
    volume?: number
    tsPayed?: number
    dependency?: boolean
    showError?: boolean
}

export const useGetTariffRate = ({
    dependency = true,
    id,
    volume,
    tsPayed,
    showError = true,
}: IGetTariff) => {
    const { fetch, result, isLoading } = useRequest({
        link: '/api/billing/v1/tariff-rate',
        method: RequestTypes.post,
    })

    useEffect(() => {
        if (dependency) {
            fetch({
                showError,
                body: { operation_type_id: id, volume: volume, ts_payed_to: tsPayed },
            })
        }
    }, [dependency])

    return {
        isLoading,
        loadingTariffs: dependency && (isLoading || !result),
        balance: `${currencyFormat(result?.available_balance)} ₽`,
        rate: `${currencyFormat(result?.rate)} ₽`,
        rateNumber: Number(result?.rate),
        payTo: result?.ts_pay_account_to,
        balanceNumber: Number(result?.available_balance),
    }
}
