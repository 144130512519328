import { RequestTypes } from '../hooks/useRequest'
import {renderMonthAsTextYear} from '../../utils/formatter'
import { currencyFormat } from '../helpers'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'

export const groupPaymentConfig = {
    link: `${GREEN_TOOLS_API_PREFIX}contracts/group-payment-consumption`,
    method: RequestTypes.post,
}

export const infoConfig = (data: any) => [
    {
        name: 'Период поставки',
        value: renderMonthAsTextYear(data.period),
    },
    {
        name: 'Количество ЗД',
        value: data.count,
    },
    {
        name: 'Общий объем, кВт*ч',
        value: currencyFormat(data.volume),
    },
]
